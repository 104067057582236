import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Server, Network, Cloud, 
  ArrowRight, Zap, Shield, Menu, 
  X, User, LogIn, UserPlus 
} from 'lucide-react';

const BackgroundParticles = () => {
  const [particles, setParticles] = useState([]);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const createParticles = () => {
      const newParticles = Array(100).fill().map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 1,
        speedX: (Math.random() - 0.5) * 0.5,
        speedY: (Math.random() - 0.5) * 0.5,
        color: `rgba(188, 143, 250, ${Math.random() * 0.4})`
      }));
      setParticles(newParticles);
    };

    const animateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;

        if (particle.x < 0) particle.x = canvas.width;
        if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        if (particle.y > canvas.height) particle.y = 0;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
      });

      requestAnimationFrame(animateParticles);
    };

    createParticles();
    animateParticles();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      createParticles();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      className="fixed inset-0 z-0 pointer-events-none" 
      style={{ mixBlendMode: 'overlay' }}
    />
  );
};

const App = () => {
  const [activeService, setActiveService] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const services = [
    {
      icon: <Server className="w-12 h-12 text-purple-500" />,
      title: "Virtual Private Servers",
      description: "High-performance, scalable VPS solutions for every need.",
      features: [
        "Instant deployment",
        "Multiple OS options",
        "Full root access",
        "Flexible scaling"
      ],
      color: "purple"
    },
    {
      icon: <Cloud className="w-12 h-12 text-purple-400" />,
      title: "Dedicated Servers",
      description: "Powerful, fully-managed dedicated server hosting.",
      features: [
        "Top-tier hardware",
        "Customizable configurations",
        "24/7 support",
        "High network bandwidth"
      ],
      color: "purple-light"
    },
    {
      icon: <Network className="w-12 h-12 text-purple-300" />,
      title: "Colocation",
      description: "Secure, reliable data center space for your infrastructure.",
      features: [
        "Enterprise-grade facilities",
        "High security",
        "Redundant power",
        "Network connectivity"
      ],
      color: "purple-lighter"
    },
    {
      icon: <Cloud className="w-12 h-12 text-purple-200" />,
      title: "IP Transit Services",
      description: "High-speed, low-latency global network connectivity.",
      features: [
        "Tier 1 network",
        "Global reach",
        "Low latency routes",
        "Redundant connections"
      ],
      color: "purple-lightest"
    }
  ];

  const navItems = [
    { label: 'Services', href: '#services' },
    { label: 'Pricing', href: '#pricing' },
    { label: 'About', href: '#about' },
    { label: 'Contact', href: '#contact' }
  ];

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-purple-50 via-white to-purple-100 text-gray-800 overflow-hidden">
      <BackgroundParticles />
      
      {/* Navigation Bar */}
      <motion.nav 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-0 left-0 right-0 z-50 bg-white/70 backdrop-blur-lg shadow-sm"
      >
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          {/* Logo */}
          <motion.div 
            whileHover={{ scale: 1.1 }}
            className="text-2xl font-bold text-purple-600"
          >
            <img src="/logo.png" alt="logo" width="62"></img>
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex space-x-8 items-center">
            {navItems.map((item) => (
              <motion.a
                key={item.label}
                href={item.href}
                whileHover={{ scale: 1.1 }}
                className="text-purple-700 hover:text-purple-900 transition-colors"
              >
                {item.label}
              </motion.a>
            ))}
            
            {/* Auth Buttons */}
            <div className="flex space-x-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                className="bg-purple-600 text-white px-4 py-2 rounded-full flex items-center"
              >
                <LogIn className="w-4 h-4 mr-2" /> Login
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center"
              >
                <UserPlus className="w-4 h-4 mr-2" /> Register
              </motion.button>
            </div>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <motion.button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              whileTap={{ scale: 0.9 }}
            >
              {mobileMenuOpen ? <X className="w-6 h-6 text-purple-600" /> : <Menu className="w-6 h-6 text-purple-600" />}
            </motion.button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {mobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-white/90 backdrop-blur-lg"
            >
              <div className="container mx-auto px-6 py-4 space-y-4">
                {navItems.map((item) => (
                  <motion.a
                    key={item.label}
                    href={item.href}
                    className="block text-purple-700 hover:text-purple-900"
                    whileTap={{ scale: 0.95 }}
                  >
                    {item.label}
                  </motion.a>
                ))}
                <div className="space-y-4 pt-4 border-t border-purple-200">
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    className="w-full bg-purple-600 text-white px-4 py-2 rounded-full flex items-center justify-center"
                  >
                    <LogIn className="w-4 h-4 mr-2" /> Login
                  </motion.button>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    className="w-full bg-purple-500 text-white px-4 py-2 rounded-full flex items-center justify-center"
                  >
                    <UserPlus className="w-4 h-4 mr-2" /> Register
                  </motion.button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>

      {/* Hero Header */}
      <motion.header 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, type: "spring" }}
        className="relative z-10 container mx-auto px-6 pt-32 text-center"
      >
        <motion.h1 
          className="text-6xl md:text-8xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-purple-900"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ 
            scale: 1, 
            opacity: 1,
            textShadow: [
              '0 0 10px rgba(128,0,128,0.3)',
              '0 0 20px rgba(128,0,128,0.2)'
            ]
          }}
          transition={{ 
            duration: 1.5, 
            type: "spring", 
            stiffness: 120 
          }}
        >
          BearCub
        </motion.h1>
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-purple-800"
        >
          Powering Your Digital Infrastructure with Premium VPS and Server Solutions
        </motion.p>
      </motion.header>

      {/* Services Grid */}
      <section className="container mx-auto px-6 py-16 relative z-10" id="services">
        <motion.div 
          className="grid md:grid-cols-4 gap-6"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
              }
            }
          }}
        >
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              variants={{
                hidden: { y: 50, opacity: 0 },
                visible: { 
                  y: 0, 
                  opacity: 1,
                  transition: { type: "spring", stiffness: 300 }
                }
              }}
              whileHover={{ 
                scale: 1.05,
                boxShadow: '0 0 15px rgba(128,0,128,0.2)'
              }}
              className={`bg-white/70 backdrop-blur-lg p-6 rounded-2xl border border-purple-100 
                transition-all duration-300 hover:border-purple-300 
                group relative overflow-hidden`}
              onClick={() => setActiveService(index === activeService ? null : index)}
            >
              <div className="flex items-center mb-4 relative z-10">
                {service.icon}
                <h3 className="ml-4 text-xl font-semibold text-purple-800">{service.title}</h3>
              </div>
              <p className="text-purple-700 mb-4 relative z-10">{service.description}</p>
              
              <AnimatePresence>
                {activeService === index && (
                  <motion.div 
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mt-4 space-y-2 relative z-10"
                  >
                    {service.features.map((feature, featureIndex) => (
                      <motion.div
                        key={feature}
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: featureIndex * 0.1 }}
                        className="flex items-center text-sm text-purple-600"
                      >
                        <Zap className="w-4 h-4 mr-2 text-purple-400" />
                        {feature}
                      </motion.div>
                    ))}
                    <motion.a 
                      href="#"
                      className="inline-flex items-center text-sm text-purple-700 hover:text-purple-900 mt-2"
                      whileHover={{ x: 5 }}
                    >
                      Learn More <ArrowRight className="w-4 h-4 ml-1" />
                    </motion.a>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </section>

      {/* Call to Action */}
      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.8 }}
        className="container mx-auto px-6 py-16 text-center relative z-10"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="bg-white/70 backdrop-blur-lg rounded-3xl p-12 max-w-4xl mx-auto"
        >
          <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-purple-900">
            Your Digital Infrastructure, Simplified
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto text-purple-800">
            Experience unparalleled performance, reliability, and flexibility with Bearcub
          </p>
          <motion.button
            whileHover={{ 
              scale: 1.1,
              boxShadow: '0 0 20px rgba(128,0,128,0.3)'
            }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-purple-600 to-purple-900
            text-white px-10 py-4 rounded-full text-lg font-semibold 
            hover:brightness-110 transition-all duration-300 
            flex items-center justify-center mx-auto space-x-3"
          >
            <Shield className="w-6 h-6 mr-2" /> Get Started
          </motion.button>
        </motion.div>
      </motion.section>
    </div>
  );
};

export default App;